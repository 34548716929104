import DataAnalysis from "../Images/dataanalysis.jpg";
import MobileApp from "../Images/mobileapp.jpg";
import MultiplayerGame from "../Images/dupme.jpg";
import PersonalSpace from "../Images/personalspace.jpg";
import Erosion from "../Images/erosion.jpg";
import Arabica from "../Images/Arabica.jpg";
import Hualumpong from "../Images/Hualumpong.jpg";
import Disgovery from "../Images/Disgovery.jpg";
var resumeLink =
  "https://drive.google.com/file/d/1iCC9rfg-x4WqirKHwfdk8whXS-BTY95T/view?usp=sharing";
var AboutData = {
  intro:
    "MSc Advanced Computer Science student at University of York with a passion for problem-solving in software development, seeking to a career in the world of technology. Ability to learn new things, adapt to new challenges, and deliver innovative solutions, Desired to contribute the skills and knowledge to a forward-thinking company. With a solid academic background, hands-on experience in coding, and a drive for continuous improvement, well-equipped to collaborate on projects and help drive success in the ever-evolving field of computer science. A motivate person with a dedication to staying current with emerging technologies, excited to bring the fresh perspective and a commitment to excellence to a role in the tech industry.",
  workexperience: [
    {
      id: 1,
      period: "November 2019",
      company: "ISE OpenHouse 2019",
      position: "Staff",
    },
    {
      id: 2,
      period: "July 2021 - September 2021",
      company: "CovidSelfCheck [Volunteer]",
      position: "Project Management Analyst",
    },
    {
      id: 3,
      period: "May 2022 - July 2022",
      company: "LINE MAN Wongnai",
      position: "Software Engineer in Test Intern",
    },
    {
      id: 4,
      period: "Jan 2023 - July 2023",
      company: "Agoda",
      position: "Database Administator Intern (Part-time)",
    },
  ],
  education: [
    {
      id: 1,
      period: "2013-2016",
      school: "Assumption College",
      degree: "Secondary School",
    },
    {
      id: 2,
      period: "2016-2019",
      school: "Thai-German Pre-Engineering School",
      school2: "King Mongkut's University of Technology North Bangkok",
      degree: "Vocational Certificate",
      major: "Electrical Engineering",
    },
    {
      id: 3,
      period: "2019-2023",
      school: "Chulalongkorn University",
      school2: "International School of Engineering, Faculty of Engineer",
      degree: "Bachelor of Engineering",
      major: "Information and Communication Engineering",
    },
    {
      id: 4,
      period: "2023-Present",
      school: "University of York",
      degree: "Master of Science",
      major: "Advanced Computer Science",
    },
  ],
};
var ContactData = {
  mail: "mailto:napat@napat.page",
  linkedin: "https://www.linkedin.com/in/napat-a/",
  youtube: "https://www.youtube.com/channel/UCO6QMLWnbmgOEoqCEu5AnKw",
  github: "https://github.com/puttersnap",
  facebook: "https://www.facebook.com/putter2001/",
  instagram: "https://www.instagram.com/puttersnap/",
};
var EngineerProjectData = [
  {
    name: "Data Analysis",
    role: "Full Stack",
    description:
      "In the project, using data packed in CSV file to analyst and plot data on graph. Python, NumPy, Pandas, and Matplotlib are tool to analyst and plot data.",
    extlink:
      "https://colab.research.google.com/drive/1NVwaPvBhBQjJ94Us44Jc0nm3s6VWozgk?usp=sharing",
    img: DataAnalysis,
  },
  {
    name: "Mobile Application",
    role: "Full Stack",
    description:
      "In this project, Ionic framework, Python, MongoDB, and flask are tool to building. This application shows the data of dominated Oscar awards since the beginning. The application can search name of actor or actress who got awards. Using MongoDB to stored data, flask, and Python to connect between MongoDB and Ionic framework. Designed user interface of application to make it beautiful and user-friendly.",
    img: MobileApp,
  },
  {
    name: "Multiplayer Game (Dupme)",
    role: "Backend",
    description:
      "Using JavaScript to create some game function for multiplayer game. HTTP protocol has been using in this game.",
    github: "https://github.com/ktnsdev/dupme-backend/wiki",
    img: MultiplayerGame,
  },
  {
    name: "Disgovery",
    role: "Frontend",
    description:
      "This is a group project building an useful application. The application is the public transits navigating app which include all useful information about public transits in Bangkok. This application is built by using React Native on front-end and nodeJs on back-end.",
    img: Disgovery,
    github: "https://github.com/disgoveryapp",
    extlink: "https://disgovery.app"
  },
];
var MediaProjectData = [
  {
    name: "Personal Space",
    role: "Producer",
    description:
      "This video is about personal space during COVID-19 pandemic. I was shooting, editing, color grading, directing and screenwriting in this project.",
    img: PersonalSpace,
  },
  {
    name: "Erosion and Transformation",
    role: "Producer",
    description:
      "This video is about erosion and transformation in personal space. I was shooting, editing, color grading, directing and screenwriting in this project.",
    img: Erosion,
  },
  {
    name: "Arabica Icon Siam",
    role: "Producer",
    description:
      "This video was shot on iPhone at Arabica Icon Siam. I was shooting, editing, color grading in this project.",
    img: Arabica,
    youtube: "https://www.youtube.com/watch?v=f-4jYn07ZlE",
  },
  {
    name: "Hualumpong",
    role: "Producer",
    description:
      "This video was shot at Hualumpong junction. I was shooting, editing, color grading in this project.",
    img: Hualumpong,
    youtube: "https://www.youtube.com/watch?v=ISymsMKrgc0",
  },
];

export {
  resumeLink,
  AboutData,
  EngineerProjectData,
  MediaProjectData,
  ContactData,
};
