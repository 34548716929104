import React, { useState } from "react";
import {
  MyCardBody,
  MyCardButton,
  MyCardContainer,
  MyCardDecription,
  MyCardImage,
  MyCardRole,
  MyCardTitle,
  MyCardIcon,
  MyCardIconAnimation,
} from "./MyCardElements";
import { BsYoutube, BsGithub, BsLink } from "react-icons/bs";

function MyCard(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div>
      <MyCardContainer onClick={handleClick}>
        <MyCardImage src={props.img} />
        <MyCardBody>
          <MyCardTitle>{props.title}</MyCardTitle>
          <MyCardRole>
            {props.role}
            <MyCardIcon>
              {props.extlink ? (
                <MyCardIconAnimation>
                  <BsLink
                    onClick={() => window.open(props.extlink, "_blank")}
                  />
                </MyCardIconAnimation>
              ) : (
                <></>
              )}
              {props.youtube ? (
                <MyCardIconAnimation>
                  <BsYoutube
                    onClick={() => window.open(props.youtube, "_blank")}
                  />
                </MyCardIconAnimation>
              ) : (
                <></>
              )}
              {props.github ? (
                <MyCardIconAnimation>
                  <BsGithub
                    onClick={() => window.open(props.github, "_blank")}
                  />
                </MyCardIconAnimation>
              ) : (
                <></>
              )}
            </MyCardIcon>
          </MyCardRole>
          {click ? (
            <MyCardDecription>{props.decription}</MyCardDecription>
          ) : (
            <MyCardDecription></MyCardDecription>
          )}
        </MyCardBody>

        <MyCardButton>{click ? <>SHOW LESS</> : <>SHOW MORE</>}</MyCardButton>
      </MyCardContainer>
    </div>
  );
}

export default MyCard;
