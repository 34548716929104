import React from "react";
import { HeaderText, ResumeButton, ResumeContent } from "../Elements";
import { resumeLink } from "../../Informations/Information.js";

function resume() {
  return (
    <div>
      <HeaderText>Résumé</HeaderText>
      <ResumeContent>
        <ResumeButton onClick={() => window.open(resumeLink, "_blank")}>
          Download
        </ResumeButton>
      </ResumeContent>
    </div>
  );
}

export default resume;
