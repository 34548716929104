import React from "react";
import {
  ContactContent,
  ContactContentMiddle,
  ContactText,
  HeaderText,
  IconAnimation,
} from "../Elements";
import {
  BsYoutube,
  BsFacebook,
  BsInstagram,
  BsGithub,
  BsLinkedin,
} from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { ContactData } from "../../Informations/Information.js";

function Contact() {
  return (
    <div>
      <HeaderText>Contact</HeaderText>
      <ContactContentMiddle>
        <ContactContent>
          <IconAnimation
            onClick={() => window.open(ContactData.mail, "_blank")}
          >
            <FiMail />
            <ContactText>napat@napat.page</ContactText>
          </IconAnimation>
          <IconAnimation
            onClick={() => window.open(ContactData.linkedin, "_blank")}
          >
            <BsLinkedin />
            <ContactText>/in/napat-a/</ContactText>
          </IconAnimation>
          <IconAnimation
            onClick={() => window.open(ContactData.youtube, "_blank")}
          >
            <BsYoutube /> <ContactText>Napat Asavarojkul</ContactText>
          </IconAnimation>
          <IconAnimation
            onClick={() => window.open(ContactData.github, "_blank")}
          >
            <BsGithub />
            <ContactText>@puttersnap</ContactText>
          </IconAnimation>
          <IconAnimation
            onClick={() => window.open(ContactData.facebook, "_blank")}
          >
            <BsFacebook /> <ContactText>Putter Asavarojkul</ContactText>
          </IconAnimation>
          <IconAnimation
            onClick={() => window.open(ContactData.instagram, "_blank")}
          >
            <BsInstagram /> <ContactText>@puttersnap</ContactText>
          </IconAnimation>
        </ContactContent>
      </ContactContentMiddle>
    </div>
  );
}

export default Contact;
