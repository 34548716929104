import React from "react";
import {
  Container,
  BannerBackground,
  BannerContent,
  BannerText,
  HomeContent,
  HomeTextContainer,
  HomeText,
  HomeButton,
} from "../Elements";
import background from "../../Images/HomepageBg.jpg";
import projectbackground from "../../Images/projectcover.jpg";
import aboutbackground from "../../Images/aboutcover.jpg";
function Home() {
  return (
    <div>
      <Container>
        <BannerBackground style={{ backgroundImage: `url(${background})` }}>
          <BannerContent>
            <BannerText>Napat Asavarojkul</BannerText>
          </BannerContent>
        </BannerBackground>
      </Container>
      <Container>
        <BannerBackground
          style={{ backgroundImage: `url(${projectbackground})` }}
        >
          <HomeContent>
            <HomeTextContainer>
              <HomeText>Project</HomeText>
              <HomeButton onClick={() => window.open("project", "_parent")}>
                Learn more
              </HomeButton>
            </HomeTextContainer>
          </HomeContent>
        </BannerBackground>
      </Container>
      <Container>
        <BannerBackground
          style={{ backgroundImage: `url(${aboutbackground})` }}
        >
          <HomeContent>
            <HomeTextContainer>
              <HomeText>About</HomeText>
              <HomeButton onClick={() => window.open("about", "_parent")}>
                Learn more
              </HomeButton>
            </HomeTextContainer>
          </HomeContent>
        </BannerBackground>
      </Container>
    </div>
  );
}
export default Home;
