import styled from "styled-components";

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-between;
  height: 30px;
  @media screen and (max-width: 420px) {
    display: block;
  }
`;
export const FooterText = styled.div`
  color: grey;
  display: flex;
  font-size: 0.8rem;
  text-transform: uppercase;
`;
export const IconAnimation = styled.div`
  color: grey;
  padding: 0 0.5rem;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    transition: 0.2s ease;
    color: black;
  }
`;
