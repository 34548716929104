import React from "react";
import MyCard from "../../Components/MyCard";
import {
  HeaderText,
  ProjectContent,
  ProjectCardContent,
  ProjectCardScroll,
  ProjectTitle,
} from "../Elements";
import {
  EngineerProjectData,
  MediaProjectData,
} from "../../Informations/Information.js";
function Project() {
  //const [Fullscreen, setFullscreen] = useState(false);
  //const handleClick = () => setFullscreen(!Fullscreen);
  //const closeFullscreen = () => setFullscreen(false);
  return (
    <div>
      <HeaderText>Project</HeaderText>
      <ProjectContent>
        <ProjectTitle>Engineering-Field</ProjectTitle>
        <ProjectCardScroll>
          <ProjectCardContent>
            {EngineerProjectData.map((data, key) => {
              return (
                <div key={key}>
                  <MyCard
                    img={data.img}
                    title={data.name}
                    role={data.role}
                    decription={data.description}
                    extlink={data.extlink}
                    youtube={data.youtube}
                    github={data.github}
                  />
                </div>
              );
            })}
          </ProjectCardContent>
        </ProjectCardScroll>
      </ProjectContent>
      <ProjectContent>
        <ProjectTitle>Media</ProjectTitle>
        <ProjectCardContent>
          {MediaProjectData.map((data, key) => {
            return (
              <div key={key}>
                <MyCard
                  img={data.img}
                  title={data.name}
                  role={data.role}
                  decription={data.description}
                  extlink={data.extlink}
                  youtube={data.youtube}
                  github={data.github}
                />
              </div>
            );
          })}
        </ProjectCardContent>
      </ProjectContent>
    </div>
  );
}

export default Project;
