import { Button } from "react-bootstrap";
import styled from "styled-components";

export const MyCardContainer = styled.div`
  width: 21rem;
  height: auto;
  overflow: hidden;
  margin-right: 1rem;
  box-shadow: 0 2px 20px grey;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

export const MyCardBody = styled.div`
  margin: 1rem;
`;

export const MyCardImage = styled.img`
  height: 12rem;
  width: 100%;
  object-fit: cover;
`;

export const MyCardTitle = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
`;

export const MyCardRole = styled.div`
  display: flex;
  font-weight: 500;
  padding: 0.5rem 0;
  color: grey;
`;

export const MyCardDecription = styled.div``;

export const MyCardButton = styled(Button)`
  padding: 0 1rem 1rem 1rem;
  font-weight: 400;
  border: none;
  background: transparent;
  color: #1e90ff;
  cursor: pointer;
`;

export const MyCardIcon = styled.div`
  padding: 0 1rem;
  font-size: 1rem;
  display: flex;
`;
export const MyCardIconAnimation = styled.div`
  padding: 0 1rem 0 0;
  color: grey;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    transition: 0.2s ease;
    color: black;
  }
`;
