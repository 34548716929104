import React from "react";
import { PageNotFoundContent, PageNotFoundText } from "../Elements";
import { MdOutlineMoodBad } from "react-icons/md";

function Pagenotfound() {
  return (
    <div>
      <PageNotFoundContent>
        <PageNotFoundText> Sorry. Page Not Found.</PageNotFoundText>
      </PageNotFoundContent>
    </div>
  );
}

export default Pagenotfound;
