import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #fff;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  z-index: 12;
`;
export const NavLogo = styled(Link)`
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  color: Grey;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem;
  height: 100%;
  transition: 0.2s ease;
  cursor: pointer;
  &.active {
    color: black;
  }
  &:hover {
    transition: 0.2s ease;
    color: black;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: grey;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
    &.active {
      color: black;
    }
    &:hover {
      color: black;
    }
  }
`;

export const Closes = styled(FaTimes)`
  display: none;
  color: grey;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
    &.active {
      color: black;
    }
    &:hover {
      color: black;
    }
  }
`;
export const MobileMenu = styled.div`
  display: flex;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 75px;
    left: -100%;
    opacity: 0;
    transition: 0.5s ease;
    background: #fff;
    ${({ click }) =>
      click &&
      `
      display: flex;
      left: 0;
      opacity: 0.9;
      z-index: 1;
      align-content: center;
      transition: 0.5s ease;
    `}
  }
`;
