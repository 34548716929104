import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Project from "./pages/Project";
import Resume from "./pages/Resume";
import Footer from "./Components/Footer";
import Pagenotfound from "./pages/PageNotFound";
import Publicspeaking from "./pages/Publicspeaking";

function App() {
  return (
    <div className="page-container">
      <div className="content-wrap">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Project />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Pagenotfound />} />
            <Route path='/publicspeakingslide' element={<Publicspeaking />}/>
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
