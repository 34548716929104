import React, { useState } from "react";
import {
  Nav,
  NavLogo,
  NavLink,
  Bars,
  Closes,
  MobileMenu,
  NavMenu,
} from "./NavbarElements";
import Logo from "../Images/nalogo.png";
function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <Nav>
        <NavLogo to="/" onClick={closeMobileMenu}>
          <img width="60px" height="auto" src={Logo} alt="logo" />
        </NavLogo>
        <MobileMenu onClick={handleClick}>
          {click ? <Closes /> : <Bars />}
        </MobileMenu>
        <NavMenu click={click}>
          <NavLink to="/" activeStyle onClick={closeMobileMenu}>
            <b>Home</b>
          </NavLink>
          <NavLink to="/project" activeStyle onClick={closeMobileMenu}>
            <b>Project</b>
          </NavLink>
          <NavLink to="/resume" activeStyle onClick={closeMobileMenu}>
            <b>Résumé</b>
          </NavLink>
          <NavLink to="/about" activeStyle onClick={closeMobileMenu}>
            <b>About</b>
          </NavLink>
          <NavLink to="/contact" activeStyle onClick={closeMobileMenu}>
            <b>Contact</b>
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
}

export default Navbar;
