import React from "react";
import { FooterContent, FooterText, IconAnimation } from "./FooterElements";
import {
  BsYoutube,
  BsFacebook,
  BsInstagram,
  BsGithub,
  BsLinkedin,
} from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { ContactData } from "../Informations/Information.js";

function Footer() {
  return (
    <FooterContent>
      <FooterText>Napat Asavarojkul</FooterText>
      <FooterText>
        Follow me:
        <IconAnimation>
          <FiMail onClick={() => window.open(ContactData.mail, "_blank")} />
        </IconAnimation>
        <IconAnimation>
          <BsLinkedin
            onClick={() => window.open(ContactData.linkedin, "_blank")}
          />
        </IconAnimation>
        <IconAnimation>
          <BsYoutube
            onClick={() => window.open(ContactData.youtube, "_blank")}
          />
        </IconAnimation>
        <IconAnimation>
          <BsGithub onClick={() => window.open(ContactData.github, "_blank")} />
        </IconAnimation>
        <IconAnimation>
          <BsFacebook
            onClick={() => window.open(ContactData.facebook, "_blank")}
          />
        </IconAnimation>
        <IconAnimation>
          <BsInstagram
            onClick={() => window.open(ContactData.instagram, "_blank")}
          />
        </IconAnimation>
      </FooterText>
    </FooterContent>
  );
}

export default Footer;
