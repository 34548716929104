import React from "react";
import { HeaderText, ResumeButton, ResumeContent } from "../Elements";
import { resumeLink } from "../../Informations/Information.js";

function Publicspeaking() {
  return (
    <div>
      <HeaderText>Public Speaking Slide</HeaderText>
      <ResumeContent>
        <ResumeButton onClick={() => window.open('https://chula-my.sharepoint.com/:p:/g/personal/6238054721_student_chula_ac_th/EdlN7EUgJuBLlqzX37Kd66EBdBy7oJ9Oersd15MEkfGOqQ?e=Hp3FUM', "_blank")}>
          Download
        </ResumeButton>
      </ResumeContent>
    </div>
  );
}

export default Publicspeaking;
