import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
`;

export const BannerBackground = styled.div`
  height: 500px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;
export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const BannerText = styled.div`
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4rem;
  text-shadow: 2px 2px 4px #000000;
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const HomeContent = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  height: 500px;
  transition: 1s ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 1s ease-in-out;
    justify-content: center;
  }
`;

export const HomeTextContainer = styled.div`
  display: block;
`;

export const HomeText = styled.div`
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 4rem;
  text-shadow: 2px 2px 4px #000000;
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const HomeButton = styled.button`
  margin: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    transition: 0.2s ease-in-out;
    color: black;
    mix-blend-mode: lighten;
    background: #ffffff;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const HeaderText = styled.div`
  color: #111;
  text-align: center;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 400;
`;

export const IconAnimation = styled.div`
  color: grey;
  padding: 1rem;
  display: flex;
  transition: 0.2s ease;
  cursor: pointer;
  &:hover {
    transition: 0.2s ease;
    color: black;
  }
`;
export const ContactContentMiddle = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContactContent = styled.div`
  width: 300px;
  padding: 1rem;
  text-align: center;
  display: block;
  justify-content: space-around;
  font-weight: 400;
  font-size: 2rem;
`;

export const ContactText = styled.div`
  font-size: 1.5rem;
  padding: 0 1rem;
`;

export const AboutContent = styled.div`
  max-width: 800px;
  padding: 2rem;
  display: block;
  justify-content: space-around;
  font-weight: 400;
`;

export const ResumeContent = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-around;
`;

export const ResumeButton = styled.button`
  border-radius: 4px;
  background: transparent;
  padding: 10px 22px;
  color: #000;
  font-size: 2rem;
  outline: none;
  border: 2px solid #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #1e90ff;
    border: 2px solid #1e90ff;
  }
`;
export const ProjectContent = styled.div``;
export const ProjectTitle = styled.div`
  padding: 0rem 2rem;
  font-size: 2rem;
  font-weight: 700;
`;
export const ProjectCardScroll = styled.div``;
export const ProjectCardContent = styled.div`
  display: flex;
  padding: 2rem;
  gap: 1rem;
  justify-content: flex-start;
  overflow: scroll;
`;
export const PageNotFoundContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const PageNotFoundText = styled.div`
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
