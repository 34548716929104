import React from "react";
import { AboutContent, HeaderText, ContactContentMiddle } from "../Elements";
import { AboutData } from "../../Informations/Information.js";

function About() {
  return (
    <div>
      <HeaderText>About</HeaderText>
      <ContactContentMiddle>
        <AboutContent>
          <h1>Hello.</h1>
          <p>{AboutData.intro}</p>
          <h2>Work Experiences</h2>
          <div>
            {AboutData.workexperience.map((data, key) => {
              return (
                <div key={key}>
                  <h4>{data.period}</h4>
                  <h4>{data.company}</h4>
                  <p>{data.position}</p>
                </div>
              );
            })}
          </div>
          <h2>Education</h2>
          <div>
            {AboutData.education.map((data, key) => {
              return (
                <div key={key}>
                  <h4>{data.period}</h4>
                  <h4>{data.school}</h4>
                  <p>{data.school2}</p>
                  <p>{data.degree}</p>
                  <p>{data.major}</p>
                </div>
              );
            })}
          </div>
        </AboutContent>
      </ContactContentMiddle>
    </div>
  );
}

export default About;
